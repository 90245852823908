import React from 'react';
import Switch from 'react-switch';

import css from './SearchSwitch.module.scss';

const SearchSwitch = (props) => {
  const {
    sessionHandler,
    showStorageKey,
    label,
  } = props;


  const [checked, setChecked] = React.useState(sessionHandler(showStorageKey));

  const handleChange = val => {
    window.sessionStorage.setItem(showStorageKey, val);
    setChecked(val);
  };

  return (
    <div className={css.wrapper}>
      <label>{label}</label>

      <Switch
        onChange={handleChange}
        checked={checked}
        uncheckedIcon={false}
        checkedIcon={false}
        boxShadow=""
        activeBoxShadow=""
        offColor="#4A4A4A"
        onColor="#2e9c2c"
        handleDiameter={20}
        width={40}
        height={24}
      />
    </div>
  );
};

export default SearchSwitch;
